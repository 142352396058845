import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/global.css";
import "../styles/header.css";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openBizInquiry = () => {
    window.open('https://forms.gle/pPAZwjwpqE21Q9a56', '_blank');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="container-header">
        <Link to="/">
          <div className="logo">STARK ACADEMY</div>
        </Link>
        <button className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className={`menu-box ${isMenuOpen ? 'open' : ''}`}>
          <nav className="navbar">
            <ul>
              <li>
                <Link to="/" onClick={toggleMenu}>홈</Link>
              </li>
              <li>
                <Link to="/courses" onClick={toggleMenu}>프로그램 참여하기</Link>
              </li>
              <li>
                <Link to="/location" onClick={toggleMenu}>오시는길</Link>
              </li>
            </ul>
          </nav>
          <button className="biz-inquiry-btn" onClick={openBizInquiry}>BIZ 문의하기</button>
        </div>
      </div>
    </header>
  );
}

export default Header;