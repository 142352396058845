import React from 'react';
import Login from '../components/Login';
import '../styles/loginPage.css';

function LoginPage() {
  return (
    <div className="login-page">
      <h1 className="login-title">스타크 아카데미</h1>
      <h2 className="login-subtitle">관리자페이지</h2>
      <Login />
    </div>
  );
}

export default LoginPage;