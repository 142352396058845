import React, { useEffect, useCallback } from 'react';
import useLogout from '../components/Logout';
import "../styles/global.css";
import "../styles/adminPage.css";

function AdminPage() {
  const logout = useLogout();

  const handleActivity = useCallback(() => {
    localStorage.setItem('lastActivityTime', Date.now().toString());
  }, []);

  useEffect(() => {
    const checkSession = () => {
      const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime'), 10);
      if (Date.now() - lastActivityTime > 30 * 60 * 1000) { // 30분
        logout();
      }
    };

    const intervalId = setInterval(checkSession, 1000); // 1초마다 체크

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('beforeunload', logout);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('beforeunload', logout);
    };
  }, [logout, handleActivity]);

  return (
    <div className='admin-page-content'>
      <h2>Admin Page</h2>
    </div>
  );
}

export default AdminPage;