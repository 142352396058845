import React, { useState } from 'react';
import { format } from 'date-fns';
import '../styles/courseExternalLink.css';

const CourseExternalLink = ({ course, onClose }) => {
  const [showNotification, setShowNotification] = useState(false);

  const handleMoveClick = (url, e) => {
    e.preventDefault();
    if (url && url !== "null" && url.trim() !== "") {
      const cleanUrl = url.replace(/^"|"$/g, ''); // Remove surrounding quotes
      window.open(cleanUrl, '_blank', 'noopener,noreferrer');
    } else {
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  return (
    <div className="course-external-link-overlay">
      <div className="course-external-link-popup">
        <h2>{course.title} - 강의 일정</h2>
        <div className="course-external-schedules">
          {course.schedules.map((schedule, index) => (
            <div key={index} className="schedule-item">
              <div className="schedule-info">
                <span className="schedule-date">{format(new Date(schedule.date), 'yyyy-MM-dd')}</span>
                <span className="schedule-time">{schedule.time}</span>
                <span className="schedule-content">{schedule.content}</span>
              </div>
              <button 
                className="move-button"
                onClick={(e) => handleMoveClick(schedule.url, e)}
              >
                {schedule.url && schedule.url !== "null" && schedule.url.trim() !== "" ? '이동하기' : '준비중'}
              </button>
            </div>
          ))}
        </div>
        <button className="close-button" onClick={onClose}>돌아가기</button>
      </div>
      {showNotification && (
        <div className="notification">
          아직 강의 링크가 열리지 않았습니다.
        </div>
      )}
    </div>
  );
};

export default CourseExternalLink;