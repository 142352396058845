import React from "react";
import ProgramOutlook from "../components/ProgramOutlook";
import CourseList from "../components/CourseList";
import BannerSuggestion from '../components/BannerSuggestion';
import "../styles/global.css";
import "../styles/coursePage.css";

function CoursePage() {
  return (
    <div className="course-page">
      <div className="top-banner">
        <div className="desc">카이스트 경영대 강의로 검증된</div>
        <h1>스타크 오리지널 커리큘럼</h1>
      </div>
      <div className="full-content">
        <ProgramOutlook />
      </div>
      <div className="content">
        <CourseList />
      </div>
      <div className="content">
      <BannerSuggestion />
      </div>
    </div>
  );
}

export default CoursePage;
