import React from 'react';
import '../styles/global.css';
import '../styles/legal-pages.css';

function Privacy() {
  return (
    <div className="legal-container">
      <h1>개인정보 처리방침</h1>
      <div className="legal-content">
        <p>
          스타크아카데미(이하 "회사"라 합니다)는 정보주체의 자유와 권리 보호를 위해 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령(이하 "관계법령"이라 합니다.) 등이 정한 바를 준수하며, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 개인정보 보호법 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
        </p>

        <h2>1. 개인정보의 처리 목적, 수집항목, 보유 및 이용기간</h2>
        <p>
          회사는 다음의 목적을 위하여 개인정보를 수집 및 이용합니다. 수집 및 이용하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </p>

        <h3>가. 회원 가입 시</h3>
        <ul>
          <li>수집 항목: 이메일(필수), 비밀번호(필수), 휴대폰 번호(필수), 이름(필수), sns ID(선택)</li>
          <li>처리 목적: 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리</li>
          <li>보유 및 이용 기간: 회원 탈퇴시 까지</li>
        </ul>

        <h3>나. 상품 구매 시</h3>
        <ul>
          <li>수집 항목: 결제 기록(결제일, 결제 금액, 결제 상품)</li>
          <li>처리 목적: 콘텐츠 구매에 대한 콘텐츠 제공</li>
          <li>보유 및 이용 기간: 회원 탈퇴시 까지</li>
        </ul>

        <h3>다. 서비스 이용 시</h3>
        <ul>
          <li>수집 항목: 시군구 단위의 주소(선택), 생년월일(선택), 성별(선택)</li>
          <li>처리 목적: 효과적 강의</li>
          <li>보유 및 이용 기간: 회원 탈퇴시 까지</li>
        </ul>

        <h3>라. 서비스 이용 과정에서 생성되어 수집되는 정보</h3>
        <ul>
          <li>수집 항목: 쿠키. 서비스 이용기록(방문일시. IP. 불량 이용 기록 등). 기기정보(고유기기식별값. OS버전 등)</li>
          <li>처리 목적: 서비스 이용 기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재</li>
          <li>보유 및 이용 기간: 회원 탈퇴시 까지</li>
        </ul>

        <h3>마. 법령에 따른 보관 의무</h3>
        <ul>
          <li>표시/광고에 관한 기록 : 6개월</li>
          <li>계약 또는 청약철회 등에 관한 기록 : 5년</li>
          <li>대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
          <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
          <li>통신비밀보호법에 따른 통신사실 확인자료 : 3개월</li>
        </ul>

        <h2>2. 만 14세 미만 아동의 개인정보 처리에 관한 사항</h2>
        <p>
          회사는 원칙적으로 만 14세 미만 아동의 개인정보를 처리하지 않습니다.
        </p>

        <h2>3. 개인정보의 제3자 제공에 관한 사항</h2>
        <p>
          회사는 정보주체의 개인정보를 개인정보의 수집 및 이용 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 개인정보를 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.
        </p>
        <ul>
          <li>정보주체의 동의를 받은 경우</li>
          <li>법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</li>
          <li>공공기관이 법령 등에서 정하는 소관 업무의 수행을 위하여 불가피한 경우</li>
          <li>정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우</li>
          <li>정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우</li>
          <li>개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 보호위원회의 심의·의결을 거친 경우</li>
          <li>조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우</li>
          <li>범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</li>
          <li>법원의 재판업무 수행을 위하여 필요한 경우</li>
          <li>형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우</li>
          <li>통계작성(상업적 목적 포함), 연구(산업적 연구 포함), 공익적 기록보존 목적 등을 위해 가명처리하여 제공하는 경우</li>
        </ul>

        <h2>4. 개인정보처리의 위탁</h2>
        <p>
          회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에 위탁하고 있으며, 위탁받은 업체가 개인정보보호법에 따라 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독을 하고 있습니다.
        </p>
        <ul>
          <li>수탁자(위탁업무 내용) : (주)카카오(이메일, 문자, 알림톡을 통한 알림 발송, 강사(강의 제공)</li>
          <li>개인정보의 보유 및 이용기간 : 회원탈퇴 시 혹은 위탁계약 종료 시까지</li>
        </ul>

        <h2>5. 개인정보의 파기</h2>
        <p>
          회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        </p>
        <p>
          이용자로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
        </p>
        <p>개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
        <ul>
          <li>파기절차: 파기 사유가 발생한 개인정보를 선정 후, 파기합니다.</li>
          <li>파기방법: 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 파쇄기에 파쇄하여 파기합니다.</li>
        </ul>

        <h2>6. 이용자와 법정대리인의 권리 및 행사방법</h2>
        <ul>
          <li>이용자는 언제든지 자신의 개인정보에 대한 열람/수정/삭제를 요청할 수 있습니다.</li>
          <li>이용자는 언제든지 개인정보 처리의 정지를 요청할 수 있으며, 법률에 특별한 규정이 있는 등의 경우에는 처리정지 요청을 거부할 수 있습니다.</li>
          <li>이용자는 언제든지 '회원탈퇴' 등을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.</li>
          <li>이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</li>
          <li>이용자 및 법정대리인의 권리는 이메일을 통해 요청할 수 있습니다. (bella@songstark.com)</li>
        </ul>
        
        <h2>7. 개인정보의 안전성 확보조치</h2>
        <p>
          회사는 개인정보의 안전성 확보를 위해 다음과 같이 조치를 취하고 있으며, 법령에서 요구하는 수준 이상으로 개인정보를 보호하기 위해 최선의 노력을 하고 있습니다.
        </p>
        <ul>
          <li>(내부관리계획 수립 및 시행) 회사는 내부관리계획을 수립하여 관리계획을 시행하며, 매년 시행 여부를 점검하고 있습니다.</li>
          <li>(개인정보 취급 담당자의 최소화 및 교육) 개인정보를 취급하는 담당자를 최소화하여 지정하고 있습니다. 또한 전사 임직원이 개인정보 보호 의무를 올바르게 준수할 수 있도록 정기적인 직원 교육을 진행하고 있습니다.</li>
          <li>(개인정보에 대한 접근 제한) 개인정보의 안전하고 투명한 관리를 위해 개인정보처리시스템의 접근 권한에 대한 차등 부여, 변경, 말소에 관한 정책을 수립하고 시행해 개인정보의 접근 통제를 위한 조치를 취하고 있으며, 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</li>
          <li>(개인정보의 암호화) 법령에서 암호화를 요구하고 있는 비밀번호 외에 이메일주소와 이름, 휴대전화번호, 주소 등을 추가로 암호화 하여 보관하고 있습니다. 또한 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 송수신하고 있습니다.</li>
          <li>(해킹 등에 대비한 기술적 대책) 해킹 등으로 인한 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있으며, 최신 보안프로그램을 이용하여 이용자들의 개인정보가 유출되거나 손상되지 않도록 방지하고 있습니다.</li>
          <li>(접속기록의 보관 및 위변조 방지) 개인정보의 분실, 위조, 변조 등을 방지하기 위해 개인정보처리시스템에 접속한 기록을 보관하고 있으며 정기적으로 점검을 진행하고 있습니다.</li>
          <li>(물리적 접근통제) 개인정보의 안전한 보관을 위해 출입통제 절차를 수립ㆍ운영하고 있습니다.</li>
        </ul>

        <h2>8. 개인정보 자동수집 장치의 설치·운영 및 그 거부에 관한 사항</h2>
        <ul>
          <li>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.</li>
          <li>쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.</li>
          <li>쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
          <li>쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</li>
          <li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
        </ul>

        <h2>9. 권익침해 구제방법</h2>
        <p>
          이용자는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        </p>
        <ul>
          <li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
          <li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
          <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
          <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
        </ul>
        <p>
          개인정보 보호 정책과 관련하여 질문, 우려 또는 이의 사항이 있는 경우 개인정보 보호 팀(개인정보 보호 담당자 포함)에 bella@songstark.com으로 문의해 주시기 바랍니다.
        </p>

        <h2>10. 개인정보처리방침 변경에 관한 사항</h2>
        <p>
          본 개인정보 처리방침은 2024. 8. 5부터 적용됩니다. 본 개인정보 처리방침 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 공지사항을 통하여 사전 공지할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 있을 경우에는 최소 30일 전에 공지합니다.
        </p>
        <ul>
          <li>공고일자 : 2024년 8월 5일</li>
          <li>시행일자 : 2024년 8월 5일</li>
        </ul>
      </div>
    </div>
  );
}

export default Privacy;