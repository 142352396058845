import React from 'react';
import '../styles/bannerSuggestion.css';

const BannerSuggestion = () => {
  const openSuggestionForm = () => {
    window.open('https://forms.gle/nRHJBKEUiJufuJrz5', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="banner-suggestion">
      <p>스타크 아카데미에 강의를 제안해주세요.</p>
      <button className="suggest-btn" onClick={openSuggestionForm}>강의 제안하기</button>
    </div>
  );
};

export default BannerSuggestion;