import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { FaBook, FaBriefcase, FaUserTie } from 'react-icons/fa';
import '../styles/global.css';
import '../styles/programOutlook.css';

const iconMap = {
  FaBook: FaBook,
  FaBriefcase: FaBriefcase,
  FaUserTie: FaUserTie,
};

const ProgramItem = ({ title, icon, items }) => {
  const navigate = useNavigate();
  const Icon = iconMap[icon];

  return (
    <div className="program-item">
      <div className="program-header">
        {Icon && <Icon className="program-icon" />}
        <h3 className="program-title">{title}</h3>
      </div>
      <div className="program-subtitle">
        {items.flatMap(item => item.target ? item.target.split(',') : [])
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((target, index) => (
            <span key={index} className="subtitle-box">{target.trim()}</span>
          ))}
      </div>
      <div className={`program-items items-count-${items.length}`}>
        {items.map((item, index) => (
          <button key={index} onClick={() => navigate(`/program/${item.id}`)} className="program-item-link">
            {item.title}
          </button>
        ))}
      </div>
    </div>
  );
};

const ProgramOutlook = () => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [programsResponse, itemsResponse] = await Promise.all([
          fetch('/data/programs.csv'),
          fetch('/data/items.csv')
        ]);

        const programsData = await programsResponse.text();
        const itemsData = await itemsResponse.text();

        const parsedPrograms = Papa.parse(programsData, { header: true }).data;
        const parsedItems = Papa.parse(itemsData, { header: true }).data;

        const programsWithItems = parsedPrograms.map(program => ({
          ...program,
          items: parsedItems.filter(item => item.program_id === program.id)
        }));

        setPrograms(programsWithItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="program-outlook">
      <div className="program-container">
        {programs.map((program, index) => (
          <ProgramItem key={index} {...program} />
        ))}
      </div>
      <p className="footnote">
        * 모든 프로그램은 KAIST 경영대 강의에 활용해온 가장 효과적이었던 책과 내용으로 구성됩니다.
      </p>
    </div>
  );
};

export default ProgramOutlook;
