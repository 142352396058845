import React from "react";
import "../styles/global.css";
import "../styles/homePage.css";
import LectureSlider from "../components/LectureSlider";
import ShortVideos from "../components/ShortVideos";

function HomePage() {
  return (
    <div className="home">
      <section className="intro">
        <div className="intro-content">
          <h1>스타크 아카데미</h1>
          <div className="intro-message">
            <p>나에게 딱 맞춘 비즈니스 코칭</p>
            <p>직장인/사업자 맞춤형 실전적용 mini-MBA</p>
          </div>
        </div>
      </section>

      <section className="lecture-slider-section">
        <div className="section-content">
          <h2>진행중인 강의</h2>
          <LectureSlider />
        </div>
      </section>

      <section className="lecture-about">
        <div className="lecture-about-content">
          <h3>
            <div className="color-secondary">지금 하는 일, 더 잘 할 수 없을까?</div>
            <br />
            Dr. Stark가 KAIST에서 <b>500명의 사업가를 코칭하며 얻은 노하우</b>로
            <br />
            당신의 업무성과 향상, 커리어/사업고민 해결을 위한 <b>실전형 교육과
            맞춤형 코칭</b>을 제공합니다.
          </h3>
        </div>
      </section>

      <section className="shorts-videos">
        <div className="section-content">
          <h2>스타크 채널, 쇼츠</h2>
          <ShortVideos />
        </div>
      </section>
      <section className="stark-introduction">
        <div className="stark-introduction-content">
          <h2>| Dr. Stark</h2>
          <ul>
            <li>
              카이스트에서 경영공학 학사, 석사, 박사학위를 받고 현재까지 교수로
              재직 중
            </li>
            <li>
              KAIST MBA 창업과정 중심으로 15년 동안 <b>1,000명 이상의 스타트업
              대표</b>들에게 강의 제공
            </li>
            <li>
              주식회사 2개, 비영리 재단 1개를 설립하여 운영 중이며 2개의 창업을
              준비 중인 연쇄창업가
            </li>
            <li>
              카이스트 우수강의상,
              문체부장관 표창
            </li>
            <li>전공분야: 산업조직론 및 게임이론, 조직 및 경영전략</li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
