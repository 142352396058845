import React, { useState, useEffect } from "react";
import '../styles/global.css';
import "../styles/shortVideos.css";

function ShortVideos() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetch('/data/main_videos.csv')
      .then(response => response.text())
      .then(data => {
        const rows = data.split('\n').slice(1);
        const parsedVideos = rows.map(row => {
          const [id, title, videoId] = row.split(',');
          return { id, title, videoId };
        });
        setVideos(parsedVideos);
      });
  }, []);

  return (
    <div className="video-grid">
      {videos.map((video) => (
        <div key={video.id} className="video-thumbnail">
          <iframe
            width="100%"
            height="500"
            src={`https://www.youtube.com/embed/${video.videoId}`}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <h3>{video.title}</h3>
        </div>
      ))}
    </div>
  );
}

export default ShortVideos;