import React from "react";
import Location from "../components/Location";
import "../styles/global.css";
import "../styles/locationPage.css";

function LocationPage() {
  return (
    <div className="location-container">
      <Location />
    </div>
  );
}

export default LocationPage;