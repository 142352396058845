import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { format, isFuture, parse } from "date-fns";
import CourseExternalLink from "./CourseExternalLink";
import "../styles/global.css";
import "../styles/courseList.css";

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [showExternalLink, setShowExternalLink] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [programsRes, itemsRes, schedulesRes] = await Promise.all([
          fetch("/data/programs.csv"),
          fetch("/data/items.csv"),
          fetch("/data/schedules.csv"),
        ]);

        const programsData = await programsRes.text();
        const itemsData = await itemsRes.text();
        const schedulesData = await schedulesRes.text();

        const parseConfig = {
          header: true,
          skipEmptyLines: true,
          transformHeader: (header) => header.trim(),
          transform: (value) => value.trim(),
          dynamicTyping: true,
        };

        const parsedPrograms = Papa.parse(programsData, parseConfig).data;
        const parsedItems = Papa.parse(itemsData, parseConfig).data;
        const parsedSchedules = Papa.parse(schedulesData, parseConfig).data;
        const currentDate = new Date();
        const coursesWithSchedules = parsedItems.map((item) => {
          const programData = parsedPrograms.find(
            (prog) => prog.id === item.program_id
          );
          return {
            ...item,
            programName: programData ? programData.name : "Unknown Program",
            schedules: parsedSchedules
              .filter((schedule) => schedule.item_id === item.id)
              .filter((schedule) => new Date(schedule.date) >= currentDate)
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .map((schedule) => ({
                ...schedule,
                url: schedule.url ? schedule.url.replace(/^"|"$/g, "") : "", // Remove surrounding quotes
              })),
          };
        });

        setCourses(coursesWithSchedules);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleApply = (course) => {
    setSelectedCourse(course);
    setShowExternalLink(true);
  };

  const addToGoogleCalendar = (schedule, course) => {
    const [startTime, endTime] = schedule.time.split("~");
    const startDate = parse(schedule.date, "yyyy-MM-dd", new Date());
    const endDate = parse(schedule.date, "yyyy-MM-dd", new Date());

    const startDateTime =
      format(startDate, "yyyyMMdd'T'") + startTime.replace(":", "") + "00";
    const endDateTime =
      format(endDate, "yyyyMMdd'T'") + endTime.replace(":", "") + "00";

    const event = {
      text: `${course.title}: ${schedule.content}`,
      dates: `${startDateTime}/${endDateTime}`,
      details: course.description,
      location: course.location,
    };

    const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      event.text
    )}&dates=${encodeURIComponent(event.dates)}&details=${encodeURIComponent(
      event.details
    )}&location=${encodeURIComponent(event.location)}`;

    window.open(url, "_blank");
  };

  const navigate = useNavigate();

  const handleCourseClick = (courseId) => {
    navigate(`/program/${courseId}`);
  };

  const handleScheduleClick = (url, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (url && url !== "null" && url.trim() !== "") {
      const cleanUrl = url.replace(/^"|"$/g, ""); // Remove surrounding quotes
      window.open(cleanUrl, "_blank", "noopener,noreferrer");
    } else {
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  return (
    <div className="course-list">
      <h2 className="section-title">강의 참여하기</h2>
      {courses.map(
        (course) =>
          course.schedules.length > 0 && (
            <div key={course.id} className="course-item">
              <div
                className="course-image"
                onClick={() => handleCourseClick(course.id)}
              >
                <img src={`/images/${course.poster_url}`} alt={course.title} />
                <div className="course-targets">
                  {course.target.split(",").map((target, index) => (
                    <span key={index} className="target-tag">
                      {target.trim()}
                    </span>
                  ))}
                </div>
              </div>
              <div className="course-details">
                <div className="course-oneline">
                  <h3 className="course-title">{course.title}</h3>
                  <button
                    className="apply-button"
                    onClick={() => handleApply(course)}
                  >
                    신청하기
                  </button>
                </div>
                <p className="course-description-l">{course.description}</p>
                <div className="course-schedules">
                  {course.schedules
                    .filter((schedule) => isFuture(new Date(schedule.date)))
                    .map((schedule, index) => (
                      <div key={index} className="schedule-item">
                        <span>
                          {format(new Date(schedule.date), "yyyy-MM-dd")}{" "}
                          {schedule.time}
                        </span>
                        <span>{schedule.content}</span>
                        <button
                          className="schedule-link"
                          onClick={(e) => handleScheduleClick(schedule.url, e)}
                        >
                          {schedule.url &&
                          schedule.url !== "null" &&
                          schedule.url.trim() !== ""
                            ? "신청하기"
                            : "준비중"}
                        </button>
                        <button
                          className="calendar-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToGoogleCalendar(schedule, course);
                          }}
                        >
                          구글 캘린더 추가
                        </button>
                      </div>
                    ))}
                </div>
                <div className="course-info">
                  <p className="info-item">
                    <strong>일시:</strong> {course.period}
                  </p>
                  <p className="info-item">
                    <strong>장소:</strong> {course.location}
                    <a
                      href={course.location_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="map-button"
                    >
                      네이버지도 확인하기
                    </a>
                  </p>
                  <p className="info-item">
                    <strong>구성:</strong> {course.composition}
                  </p>
                </div>
              </div>
            </div>
          )
      )}
      <h2 className="section-title">진행 예정 강의</h2>
      {courses.map(
        (course) =>
          course.schedules.length === 0 && (
            <div key={course.id} className="course-item upcoming">
              <div className="course-details">
                <h3 className="course-title">{course.title}</h3>
                <p className="course-description-l">{course.description}</p>
                <p>상세 일정은 추후 공개됩니다.</p>
              </div>
            </div>
          )
      )}
      {showExternalLink && (
        <CourseExternalLink
          course={selectedCourse}
          onClose={() => setShowExternalLink(false)}
        />
      )}
      {showNotification && (
        <div className="notification">아직 강의 링크가 열리지 않았습니다.</div>
      )}
    </div>
  );
};

export default CourseList;
