import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { format, isPast, isFuture, parseISO } from 'date-fns';
import Location from './Location';
import BannerSuggestion from './BannerSuggestion';
import '../styles/programDetail.css';

const ProgramDetail = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [showAllSchedules, setShowAllSchedules] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemsRes, schedulesRes] = await Promise.all([
          fetch('/data/items.csv'),
          fetch('/data/schedules.csv')
        ]);

        const itemsData = await itemsRes.text();
        const schedulesData = await schedulesRes.text();

        const parseConfig = {
          header: true,
          skipEmptyLines: true,
          transformHeader: (header) => header.trim(),
        };

        const parsedItems = Papa.parse(itemsData, parseConfig).data;
        const parsedSchedules = Papa.parse(schedulesData, parseConfig).data;

        const selectedCourse = parsedItems.find(item => item.id === id);
        const courseSchedules = parsedSchedules
          .filter(schedule => schedule.item_id === id)
          .map(schedule => ({
            ...schedule,
            url: schedule.url ? schedule.url.trim().replace(/^"|"$/g, '') : '',
            date: parseISO(schedule.date)
          }))
          .sort((a, b) => {
            if (isFuture(a.date) && isFuture(b.date)) {
              return a.date - b.date;
            } else if (isPast(a.date) && isPast(b.date)) {
              return b.date - a.date;
            } else {
              return isFuture(a.date) ? -1 : 1;
            }
          });

        setCourse(selectedCourse);
        setSchedules(courseSchedules);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleScheduleClick = (url, isPastSchedule, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPastSchedule) {
      return; // 과거 일정은 클릭 불가
    }
    if (url && url !== "") {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    }
  };

  const hasValidUrl = (url) => url && url !== "";

  if (!course) return <div>Loading...</div>;

  return (
    <div className="program-detail">
      <div className="course-header">
        <img src={`/images/${course.poster_url}`} alt={course.title} />
        <h1>{course.title}</h1>
        <p className="course-description">{course.description}</p>
        <div className="course-info-pd">
          <p><strong>일시:</strong> {course.period}</p>
          <p><strong>장소:</strong> {course.location}</p>
          <p><strong>구성:</strong> {course.composition}</p>
        </div>
      </div>

      <div className="schedules">
        <h2>강의 일정</h2>
        {schedules
          .filter((_, index) => showAllSchedules || index < 3)
          .map((schedule, index) => {
            const isPastSchedule = isPast(schedule.date);
            return (
              <div 
                key={index} 
                className={`schedule-item ${isPastSchedule ? 'past' : ''}`}
              >
                <span className="schedule-date">{format(schedule.date, 'yyyy-MM-dd')}</span>
                <span className="schedule-time">{schedule.time}</span>
                <span className="schedule-content">{schedule.content}</span>
                <button 
                  className={`schedule-link ${isPastSchedule ? 'past' : ''}`}
                  onClick={(e) => handleScheduleClick(schedule.url, isPastSchedule, e)}
                  disabled={isPastSchedule}
                >
                  {isPastSchedule ? '진행완료' : (hasValidUrl(schedule.url) ? '신청하기' : '준비중')}
                </button>
              </div>
            );
          })}
        {schedules.length > 3 && (
          <button className="show-more-button" onClick={() => setShowAllSchedules(!showAllSchedules)}>
            {showAllSchedules ? '접기' : '더보기'}
          </button>
        )}
      </div>

      <Location />
      <BannerSuggestion currentCourseId={id} />

      {showNotification && (
        <div className="notification">
          아직 강의 링크가 열리지 않았습니다.
        </div>
      )}
    </div>
  );
};

export default ProgramDetail;