import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Google Tag Manager noscript
const gtmNoscript = (
  <noscript>
    <iframe
      src="https://www.googletagmanager.com/ns.html?id=GTM-K8PQZ8MH"
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
      title="Google Tag Manager"
    ></iframe>
  </noscript>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {gtmNoscript}
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
