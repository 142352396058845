import React from "react";
import "../styles/global.css";
import "../styles/location.css";
import KakaoMap from "./KakaoMap";

const Location = () => {
  return (
    <div className="location-container">
      <h2 className="location-title">오시는 길</h2>
      <div className="location-content">
        <div className="location-map">
          <KakaoMap />
        </div>
        <div className="location-info">
          <div className="address-info">
            <p>
              <strong>주소:</strong> 서울특별시 강남구 논현로152길 7 한성빌딩
              302호
            </p>
            <p>
              <strong>TEL:</strong> 070-8983-7804
            </p>
            <p>
              <strong>Email:</strong> bella@songstark.com
            </p>
          </div>
          <div className="transportation-info">
            <h3>교통편</h3>
            <div className="transport-item-group">
              <div className="transport-item">
                <i className="transport-icon bus-icon"></i>
                <p>
                  국민은행압구정종합금융센터 도보
                  4분(147, 148, 343, 463, 4211), 신사동교회 정류소 도보 2분
                  (145, 343, 4212), 강남을지병원 정류소 도보 5분(강남 08, 147,
                  463, 4211)
                </p>
              </div>
              <div className="transport-item">
                <i className="transport-icon subway-icon"></i>
                <p>
                  압구정역 3번출구 도보 10분
                </p>
              </div>
            </div>
          </div>
          <div className="parking-info">
            <p>
              <strong>주의:</strong> 주차공간이 마련되어있지 않습니다. 대중교통
              이용을 권장드립니다.
            </p>
            <p>
              <strong>인근 주차장:</strong> 호텔선샤인(오후 4시까지 출차)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;