import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthProvider';
import Header from './components/Header';
import Footer from './components/Footer';
import ProgramDetail from './components/ProgramDetail';
import HomePage from './pages/HomePage';
import CoursePage from './pages/CoursePage';
import LocationPage from './pages/LocationPage';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';

// ProtectedRoute 컴포넌트 정의
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function AppContent() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/courses" element={<CoursePage />} />
        <Route path="/location" element={<LocationPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/program/:id" element={<ProgramDetail />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;