import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import '../styles/global.css';
import '../styles/lectureSlider.css';

function LectureSlider() {
  const [lectures, setLectures] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/items.csv');
        const reader = response.body.getReader();
        const result = await reader.read();
        const decoder = new TextDecoder('utf-8');
        const csv = decoder.decode(result.value);
        const parsedData = Papa.parse(csv, { header: true }).data;

        const sliderLectures = parsedData
          .filter(item => item.show_in_slider === 'Yes')
          .map((item, index) => ({
            id: index + 1,
            image: `/images/${item.w_poster_url}`,
            title: item.title
          }));

        setLectures(sliderLectures);
      } catch (error) {
        console.error("Error fetching lecture data:", error);
      }
    };

    fetchData();
  }, []);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % lectures.length);
  }, [lectures.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + lectures.length) % lectures.length);
  }, [lectures.length]);

  useEffect(() => {
    if (lectures.length > 0) {
      const timer = setInterval(nextSlide, 5000);
      return () => clearInterval(timer);
    }
  }, [lectures, nextSlide]);

  const getVisibleSlides = useCallback(() => {
    if (lectures.length === 0) return [];
    const slides = [];
    for (let i = -1; i <= 1; i++) {
      const index = (currentIndex + i + lectures.length) % lectures.length;
      slides.push(lectures[index]);
    }
    return slides;
  }, [lectures, currentIndex]);

  const handleSlideClick = useCallback((index) => {
    if (index === 0) prevSlide();
    if (index === 2) nextSlide();
  }, [nextSlide, prevSlide]);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = useCallback(() => {
    if (touchStartX.current - touchEndX.current > 75) {
      nextSlide();
    }
    if (touchEndX.current - touchStartX.current > 75) {
      prevSlide();
    }
    touchStartX.current = null;
    touchEndX.current = null;
  }, [nextSlide, prevSlide]);

  const handleActiveSlideClick = useCallback(() => {
    const activeLecture = lectures[currentIndex];
    if (activeLecture) {
      navigate(`/program/${activeLecture.id}`);
    }
  }, [lectures, currentIndex, navigate]);

  if (lectures.length === 0) return null;

  return (
    <div
      className="lecture-slider"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="slider-content">
        {getVisibleSlides().map((lecture, index) => (
          <div
            key={lecture.id}
            className={`slide ${index === 1 ? 'active' : ''}`}
            onClick={() => (index === 1 ? handleActiveSlideClick() : handleSlideClick(index))}
          >
            <img src={lecture.image} alt={lecture.title} />
            {index === 1 && (
              <div className="slider-counter">
                {currentIndex + 1} / {lectures.length}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LectureSlider;
