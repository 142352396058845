import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/global.css';
import '../styles/footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <p><strong>주식회사 송앤스타크코아퍼레이션</strong> (Song & Stark Cooperation Inc.)</p>
          <p>사업자등록번호: 495-87-02963 | 대표: 송완</p>
          <p>대표번호: 070-8983-7804 | 이메일: wan@songstark.com</p>
          <p>주소: 08393 서울특별시 구로구 디지털로32가길 16, 402-1호 (구로동, 파트너스타워2차)</p>
        </div>
        <div className="footer-right">
          <div className="footer-links">
            <Link to="/terms">이용약관</Link>
            <Link to="/privacy">개인정보처리방침</Link>
            <Link to="/admin">관리자 페이지</Link>
          </div>
          <p className="copyright">© Copyright 2024. <strong>Song & Stark Cooperation Inc.</strong> All rights reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;