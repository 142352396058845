import React from 'react';
import '../styles/global.css';
import '../styles/legal-pages.css';

function Terms() {
  return (
    <div className="legal-container">
      <h1>이용약관</h1>
      <div className="legal-content">
        <h2>1. 서비스 이용약관</h2>
        <p>이 약관은 주식회사 송앤스타크코아퍼레이션(이하 "회사")이 제공하는 모든 서비스(이하 "서비스")의 이용 조건 및 절차, 회사와 회원 간의 권리, 의무, 책임 사항 등 기본적인 사항을 규정합니다.</p>
        
        <h2>2. 용어의 정의</h2>
        <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <ul>
          <li>"회원"이란 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 자를 말합니다.</li>
          <li>"아이디(ID)"란 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다.</li>
        </ul>

        {/* 추가적인 약관 내용 */}
      </div>
    </div>
  );
}

export default Terms;